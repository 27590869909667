import { ApolloClient, InMemoryCache, split } from '@apollo/client';
import { ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';

import { nullable } from './helpers/apollo';
import { createUploadLink } from 'apollo-upload-client';

import Auth from './helpers/auth';
import customFetch from './helpers/customFetch';
import { createClient } from 'graphql-ws';

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      console.log(err, 'GLOBAL ERROR');
      if (err.extensions.code === 'UNAUTHENTICATED' && Auth.getToken()) {
        Auth.logout();
        window.location.href = '/sign-in';
      }
    }
  }
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_WS_URL!,
    retryAttempts: 10,
    retryWait: async () => {
      await new Promise(resolve => setTimeout(resolve, 1000));
    },
    connectionParams: async () => {
      const token = localStorage.getItem('token');
      return {
        Authorization: `Bearer ${token}`,
        reconnect: true,
      };
    },
    on: {
      connected: received => {
        // console.log(received, 'connect');
      },
      closed: received => {
        console.log(received, 'closed');
      },
    },
  }),
);

const link = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
  fetch: customFetch,
});

const authLink = setContext(
  (_: any, { headers }: { headers: Record<string, string> }) => {
    const token = localStorage.getItem('token');

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  },
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(link),
);

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, splitLink]),
  connectToDevTools: true,
  cache: new InMemoryCache({
    typePolicies: {
      Status: {
        fields: {
          title: nullable(),
          color: nullable(),
          taskOrder: nullable(),
          actions: nullable(),
        },
      },
      Action: {
        fields: {
          title: nullable(),
          description: nullable(),
          createdAt: nullable(),
          dueDate: nullable(),
          assignee: nullable(),
          startDate: nullable(),
          attachments: nullable(),
          priority: nullable(),
          storyPoints: nullable(),
          parentId: nullable(),
          totalSubtasksCount: nullable(),
          updatedAt: nullable(),
          completedAt: nullable(),
          projectId: nullable(),
          isUrgent: nullable(),
          isArchived: nullable(),
          labels: nullable(),
          statusId: nullable(),
          rank: nullable(),
        },
      },
    },
  }),
});

export default client;
