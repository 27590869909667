import { Button } from 'src/components/design-system';
import styled, { css } from 'styled-components';

export const Content = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1050;
  pointer-events: none;
`;

type ConfirmButtonProps = {
  background: string;
};

export const ConfirmButton = styled(Button)<ConfirmButtonProps>`
  flex: 1;
  padding: 15px 20px;
  border-radius: 0;
  font-weight: bold;
  &:hover {
    color: ${({ theme }) => theme.color.white};
    background: ${({ background }) => background};
  }
`;
