import { useNotification } from 'src/components/design-system';

import {
  useCreateTaskTemplateMutation,
  useDeleteTaskTemplateMutation,
  CreateTaskTemplateMutationVariables,
  DeleteTaskTemplateMutationVariables,
} from 'src/generated';

export const useTaskTemplate = () => {
  const notification = useNotification();
  const [createTaskTemplate] = useCreateTaskTemplateMutation();
  const [deleteTaskTemplate] = useDeleteTaskTemplateMutation();

  const onCreateTaskTemplate = async ({
    workspaceId,
    title,
    description,
    storyPoints,
    startDate,
    priority,
    isUrgent,
    dueDate,
    assignee,
  }: CreateTaskTemplateMutationVariables) => {
    try {
      const { data } = await createTaskTemplate({
        variables: {
          workspaceId,
          title,
          description,
          storyPoints,
          startDate,
          priority,
          isUrgent,
          dueDate,
          assignee,
        },
      });

      notification.show({
        title: 'Task Template Created',
        message: `Task template "${title}" was created successfully`,
        variant: 'success',
      });

      return data?.createTaskTemplate;
    } catch (error) {
      console.error(error);
    }
  };

  const onDeleteTaskTemplate = async ({
    templateId,
  }: DeleteTaskTemplateMutationVariables) => {
    try {
      await deleteTaskTemplate({
        variables: { templateId },
        optimisticResponse: {
          __typename: 'Mutation',
          deleteTaskTemplate: {
            __typename: 'TaskTemplate',
            _id: templateId,
          },
        },
        update: cache => {
          cache.evict({ id: `TaskTemplate:${templateId}` });
        },
      });

      notification.show({
        title: 'Task Template Deleted',
        message: 'Task template was deleted successfully',
        variant: 'success',
      });
    } catch (error) {
      console.error(error);
    }
  };

  return {
    onCreateTaskTemplate,
    onDeleteTaskTemplate,
  };
};
