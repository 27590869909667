import { Flex } from 'src/components/design-system';
import styled, { css } from 'styled-components';

type TemplateProps = {
  selected?: boolean;
};

export const Template = styled(Flex)<TemplateProps>`
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.background.tertiary};
  }
  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.background.secondary};
    &:hover {
      background-color: ${({ theme }) => theme.background.tertiary};
    }
  }
  ${({ selected }) =>
    selected &&
    css`
      && {
        background: ${({ theme }) => theme.color.success};
        color: ${({ theme }) => theme.color.white};

        &:hover {
          background: ${({ theme }) => theme.color.success};
        }
      }
    `}
`;

export const Title = styled.h4`
  width: 100%;
  margin: 0;
`;
