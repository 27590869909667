import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
  ExpandedState,
  getExpandedRowModel,
} from '@tanstack/react-table';
import React, { useCallback, useEffect, useState } from 'react';

import { Flex } from 'src/components/design-system';

import { useTask } from 'src/hooks/useTask';

import { priorities } from 'src/common/constants';
import { DateFormats, DateHelpers } from 'src/common/helpers';

import DueDate from '../Board/Action/DueDate/DueDate';
import { storyPointsList } from '../Board/Action/constants';
import PriorityDropdown from '../PriorityDropdown/PriorityDropdown';
import StatusDropdown from '../StatusDropdown/StatusDropdown';
import StoryPointsDropdown from '../StoryPointsDropdown/StoryPointsDropdown';
import TaskAssignee from '../Task/TaskAssignee/TaskAssignee';
import DraggableTableHeader from './DraggableTableHeader/DraggableTableHeader';
import RowDragHandleCell from './RowDragHandleCell/RowDragHandleCell';
import SelectedRowsActions from './SelectedRowsActions/SelectedRowsActions';
import TableCreateTask from './TableCreateTask/TableCreateTask';
import TableTaskNameCell from './TableTaskNameCell/TableTaskNameCell';
import Tbody from './Tbody/Tbody';
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  type DragEndEvent,
  type DragStartEvent,
  type UniqueIdentifier,
  useSensor,
  useSensors,
  Modifier,
} from '@dnd-kit/core';
import {
  restrictToVerticalAxis,
  restrictToHorizontalAxis,
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import { calculateTaskRank } from 'src/helpers/task';

import { TableTask } from './types';

import {
  useOnUpdateTaskSubscription,
  useOnCreateTaskSubscription,
  useOnDeleteTasksSubscription,
} from 'src/generated';

const columnHelper = createColumnHelper<TableTask>();

const columns = [
  columnHelper.accessor('drag-handle', {
    header: '',
    cell: ({ row }) => {
      if (row.depth > 0) {
        return null;
      }
      return <RowDragHandleCell row={row} />;
    },
    size: 40,
    minSize: 40,
  }),
  columnHelper.accessor('count', {
    header: () => '',
    cell: info => {
      if (info.row.depth > 0) {
        return null;
      }
      return <span>{info.row.index + 1}</span>;
    },
    size: 40,
    minSize: 40,
  }),
  columnHelper.accessor('selection', {
    header: ({ table }) => (
      <div>
        <input
          type="checkbox"
          checked={table.getIsAllRowsSelected()}
          onChange={() => {
            table.toggleAllRowsSelected();
          }}
        />
      </div>
    ),
    cell: ({ row }) => {
      return (
        <div>
          <input
            type="checkbox"
            checked={row.getIsSelected()}
            onChange={() => {
              row.toggleSelected();
            }}
          />
        </div>
      );
    },
    size: 40,
    minSize: 40,
  }),
  columnHelper.accessor('title', {
    header: () => <span>{'Task name'}</span>,
    cell: props => <TableTaskNameCell props={props} />,
    size: 650,
    minSize: 650,
  }),
  columnHelper.accessor('statusId', {
    header: 'Status',
    cell: props => {
      const { table, row, cell } = props;
      const { id } = cell;
      // @ts-ignore
      const { editingCell, updateData } = table.options.meta;

      const isEditing = id === editingCell;

      const { _id, statusId } = row.original;
      return (
        <StatusDropdown
          id={id}
          rowIndex={row.index}
          taskId={_id}
          statusId={statusId}
          isEditing={isEditing}
          updateData={updateData}
        />
      );
    },
    size: 200,
  }),
  columnHelper.accessor('assignee', {
    header: 'Assignee',
    cell: cell => {
      const { _id, assignee } = cell.row.original;
      return <TaskAssignee taskId={_id} assignee={assignee} />;
    },
    size: 200,
  }),
  // columnHelper.accessor('labels', {
  //   header: 'Labels',
  //   // cell: cell => {
  //   //   const { _id, labels } = cell.row.original;
  //   //   return <LabelsDropdown taskId={_id} labels={labels} />;
  //   // },
  //   cell: info => <strong>{'asdasd'}</strong>,
  //   size: 200,
  //   minSize: 200,
  //   maxSize: 200,
  // }),
  columnHelper.accessor('priority', {
    header: () => 'Priority',
    cell: props => {
      const { table, row, cell } = props;
      const { id } = cell;
      // @ts-ignore
      const { editingCell, updateData } = table.options.meta;

      const isEditing = id === editingCell;

      const { _id, priority: taskPriority } = props.row.original;

      const priority = priorities.find(({ value }) => value === taskPriority);
      return (
        <PriorityDropdown
          id={id}
          rowIndex={row.index}
          taskId={_id}
          priority={priority}
          isEditing={isEditing}
          updateData={updateData}
        />
      );
    },
    size: 200,
  }),
  columnHelper.accessor('storyPoints', {
    header: () => 'Story Points',
    cell: props => {
      const { table, row, cell } = props;
      const { id } = cell;
      // @ts-ignore
      const { editingCell, updateData } = table.options.meta;

      const isEditing = id === editingCell;

      const { _id, storyPoints } = cell.row.original;

      const storyPoint = storyPointsList.find(
        ({ value }) => value === storyPoints,
      );

      return (
        <StoryPointsDropdown
          id={id}
          rowIndex={row.index}
          taskId={_id}
          storyPoint={storyPoint}
          isEditing={isEditing}
          updateData={updateData}
        />
      );
    },
    size: 200,
  }),
  columnHelper.accessor('dueDate', {
    header: () => 'Due Date',
    cell: cell => <DueDate task={cell.row.original} />,
    size: 250,
  }),
  columnHelper.accessor('createdAt', {
    header: () => 'Created On',
    cell: info =>
      DateHelpers.formatDate(
        info.renderValue() as unknown as Date,
        DateFormats.DayLongMonthYear,
      ),

    size: 200,
  }),
  columnHelper.accessor('updatedAt', {
    header: () => 'Updated At',
    cell: info =>
      DateHelpers.formatDate(
        info.renderValue() as unknown as Date,
        DateFormats.DayLongMonthYear,
      ),

    size: 200,
  }),
  columnHelper.accessor('completedAt', {
    header: () => 'Completed At',
    cell: info =>
      DateHelpers.formatDate(
        info.renderValue() as unknown as Date,
        DateFormats.DayLongMonthYear,
      ),

    size: 200,
  }),
];

const TableView = ({ tasks }) => {
  const [data, setData] = useState(() => tasks);
  const [modifiers, setModifiers] = useState<Modifier[]>([]);
  const [editingCell, setEditingCell] = useState<string | null>(null);
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [columnOrder, setColumnOrder] = React.useState<string[]>(() =>
    columns.map(c => c.accessorKey!),
  );
  const { onUpdateTask } = useTask();

  useOnUpdateTaskSubscription({
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (!subscriptionData.data) return;
      const updatedTask = subscriptionData.data.onUpdateTask;
      setData(prev => {
        return prev.map(task => {
          if (task._id === updatedTask._id) {
            return updatedTask;
          }
          return task;
        });
      });
    },
  });

  useOnCreateTaskSubscription({
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (!subscriptionData.data) return;
      const newTask = subscriptionData.data.onCreateTask;
      setData(prev => {
        return [newTask, ...prev];
      });
    },
  });

  useOnDeleteTasksSubscription({
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (!subscriptionData.data) return;
      const deletedTask = subscriptionData.data.onDeleteTasks;
      const deletedTaskIds = deletedTask.map(task => task._id);

      setData(prev => {
        return prev.filter(task => !deletedTaskIds.includes(task._id));
      });
    },
  });

  // update data when user filters tasks
  useEffect(() => {
    if (tasks.length === data.length) return;
    setData(tasks);
  }, [tasks]);

  const dataIds = React.useMemo<UniqueIdentifier[]>(
    () => data?.map(({ _id }) => _id),
    [data],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    state: {
      columnOrder,
      expanded,
    },
    onExpandedChange: setExpanded,
    // @ts-ignore
    getSubRows: row => row.subtasks,
    onColumnOrderChange: setColumnOrder,
    getRowId: row => row._id, //required because row indexes will change
    enableColumnResizing: true,
    enableRowSelection: true,
    columnResizeMode: 'onChange',
    meta: {
      editingCell,
      setEditingCell,
      updateData: (rowIndex, cellId, value) => {
        // Skip page index reset until after next rerender
        // skipAutoResetPageIndex();
        setData(old =>
          old.map((row, index) => {
            if (index === rowIndex) {
              const test = cellId.split('_');

              return {
                ...old[rowIndex]!,
                [test[1]]: value,
              };
            }
            return row;
          }),
        );
      },
    },
    // for debugging
    // debugTable: true,
    // debugHeaders: true,
    // debugColumns: true,
  });

  // reorder rows after drag & drop
  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active && over && active.id !== over.id) {
      if (
        columnOrder.includes(active.id as any) ||
        columnOrder.includes(over.id as any)
      ) {
        setColumnOrder(columnOrder => {
          const oldIndex = columnOrder.indexOf(active.id as string);
          const newIndex = columnOrder.indexOf(over.id as string);
          return arrayMove(columnOrder, oldIndex, newIndex);
        });
        return;
      }

      const oldIndex = dataIds.indexOf(active.id);
      const newIndex = dataIds.indexOf(over.id);
      const newColumns = arrayMove(data, oldIndex, newIndex) as TableTask[];

      setData(newColumns);

      const aboveTask = newColumns[newIndex + 1];
      const belowTask = newColumns[newIndex - 1];
      const rank = calculateTaskRank(aboveTask, belowTask);

      onUpdateTask({
        taskId: active.id as string,
        rank,
      });
    }
  }

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      setExpanded({});
      setModifiers(() => {
        if (columnOrder.includes(event.active.id as any)) {
          return [restrictToHorizontalAxis];
        }
        return [restrictToVerticalAxis];
      });
    },
    [columnOrder],
  );

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );

  const selectedRows = Object.keys(table.getState().rowSelection);

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={modifiers}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      sensors={sensors}
    >
      <div
        onClick={() => {
          setEditingCell(null);
        }}
      >
        <div
          {...{
            style: {
              width: table.getCenterTotalSize(),
            },
          }}
        >
          {/*Header*/}
          <div>
            {table.getHeaderGroups().map(headerGroup => (
              <Flex alignItems="center" key={headerGroup.id}>
                <SortableContext
                  items={columnOrder}
                  strategy={horizontalListSortingStrategy}
                >
                  {headerGroup.headers.map(header => (
                    <DraggableTableHeader key={header.id} header={header} />
                  ))}
                </SortableContext>
              </Flex>
            ))}
          </div>

          {/*Header*/}
          <Tbody
            table={table}
            dataIds={dataIds}
            columnOrder={columnOrder}
            setEditingCell={setEditingCell}
            editingCell={editingCell}
          />
          <TableCreateTask />
        </div>
      </div>
      <>
        {selectedRows.length > 0 && (
          <SelectedRowsActions selectedRows={selectedRows} table={table} />
        )}
      </>
    </DndContext>
  );
};

export default TableView;
