import React, { useEffect, useRef } from 'react';

import useInViewport from 'src/hooks/common/useInViewport';

import('@lottiefiles/lottie-player/dist/tgs-player');

type TgsStickerProps = {
  url: string;
  size?: number;
  playInViewport?: boolean;
};

const TgsPlayer = ({
  url,
  size = 200,
  playInViewport = false,
}: TgsStickerProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const isInViewport = useInViewport(ref, {
    rootMargin: '-350px 0px',
    threshold: 0.1,
  });

  useEffect(() => {
    if (!playInViewport) return;

    const element = ref.current;
    if (!element) return;

    // @ts-ignore
    isInViewport ? element?.play() : element?.pause();
  }, [playInViewport, isInViewport]);

  return (
    <tgs-player
      ref={ref}
      loop={true}
      count={5}
      hover={true}
      mode="normal"
      src={url}
      style={{ height: `${size}px`, width: `${size}px`, margin: 0 }}
    />
  );
};

export default TgsPlayer;
