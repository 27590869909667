import { useParams } from 'react-router-dom';

import { useApolloClient } from '@apollo/client';

import { GET_PROJECT_DETAILS, GET_PROJECTS } from 'src/graphql/projects';

export * from './update-kanban-cache';

export const useProjectFromProjects = (projectId: string) => {
  const client = useApolloClient();

  const { projects } = client.readQuery({
    query: GET_PROJECTS,
  });

  return projects.find(project => project._id === projectId);
};

export const useProject = () => {
  const { id: projectId } = useParams();
  const client = useApolloClient();

  if (!projectId) {
    return {
      project: {
        members: [],
        labels: [],
      },
    };
  }

  return (
    client.readQuery({
      query: GET_PROJECT_DETAILS,
      variables: { projectId },
    }) || { project: { members: [], labels: [] } }
  );
};

export const useProjects = () => {
  const client = useApolloClient();

  const { projects } = client.readQuery({
    query: GET_PROJECTS,
  });

  return projects;
};

export const nullable = () => {
  // Create a generic field policy that allows any field to be null by default:
  return {
    read(existing = null) {
      return existing;
    },
  };
};
