import { useParams } from 'react-router-dom';

import { useUserWorkspace } from 'src/store';

import {
  useCreateLabelMutation,
  useDeleteLabelMutation,
  useUpdateLabelMutation,
} from 'src/generated';

export const useLabels = () => {
  const projectId = useParams().id!;
  const workspaceId = useUserWorkspace();

  const [deleteLabel] = useDeleteLabelMutation();
  const [editLabel] = useUpdateLabelMutation();
  const [createLabel] = useCreateLabelMutation();

  const handleCreateLabel = async value => {
    const { title, color } = value;

    try {
      await createLabel({
        variables: {
          workspaceId,
          projectId,
          title,
          color,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          createLabel: {
            __typename: 'Label',
            _id: `temp-${Date.now()}`, // Generate a unique temporary ID
            title,
            color,
          },
        },
        update: (cache, { data }) => {
          if (!data?.createLabel) return;

          const newLabel = data.createLabel;

          // Use cache.modify to update the labels field
          cache.modify({
            fields: {
              labels(existingLabels = [], { readField }) {
                // Avoid duplicates if an optimistic response exists
                if (
                  existingLabels.some(
                    label => readField('_id', label) === newLabel._id,
                  )
                ) {
                  return existingLabels;
                }

                return [...existingLabels, newLabel];
              },
            },
          });
        },
      });
    } catch (error) {
      console.error('Failed to create label:', error);
    }
  };

  const handleDeleteLabel = async labelId => {
    await deleteLabel({
      variables: {
        id: labelId,
      },
      update: (cache, { data }) => {
        if (!data?.deleteLabel) return;

        const deletedLabelId = cache.identify(data.deleteLabel);

        // Evict the deleted label from the cache
        cache.evict({ id: deletedLabelId });

        // Optionally update the `labels` list field to remove the deleted label
        cache.modify({
          fields: {
            labels(existingLabels = [], { readField }) {
              return existingLabels.filter(
                label => readField('_id', label) !== data.deleteLabel._id,
              );
            },
          },
        });

        // Garbage collect to clean up dangling references
        cache.gc();
      },
    });
  };

  const handleEditLabel = async (labelId, values) => {
    const { title, color } = values;

    await editLabel({
      variables: { id: labelId, title, color },
      optimisticResponse: {
        __typename: 'Mutation',
        updateLabel: {
          __typename: 'Label',
          _id: labelId,
          title,
          color,
        },
      },
      update: (cache, { data }) => {
        if (!data?.updateLabel) return;

        const updatedLabel = data.updateLabel;

        // Update the cache for the `labels` field
        cache.modify({
          fields: {
            labels(existingLabels = [], { readField }) {
              return existingLabels.map(label =>
                readField('_id', label) === updatedLabel._id
                  ? { ...label, ...updatedLabel }
                  : label,
              );
            },
          },
        });
      },
    });
  };
  return {
    onCreateLabel: handleCreateLabel,
    onDeleteLabel: handleDeleteLabel,
    onEditLabel: handleEditLabel,
  };
};
