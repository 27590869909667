import React from 'react';

import { useApolloClient } from '@apollo/client';

import { TableTask } from '../../../pages/Project/components/Table/types';

import { useUser } from 'src/hooks/useUser';

import { useProjects } from 'src/helpers/apollo';

import { GET_PROJECTS } from '../../../graphql/projects';
import ProjectsListItem from './ProjectsListItem';
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  type DragEndEvent,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToVerticalAxis,
  restrictToFirstScrollableAncestor,
} from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';

import { Container, List } from './styles';

const ProjectsList = () => {
  const projects = useProjects();
  const client = useApolloClient();
  const { onUpdateUser } = useUser();

  const projectIds = projects.map(({ _id }) => _id);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const oldIndex = projectIds.indexOf(active.id);
      const newIndex = projectIds.indexOf(over.id);
      const newProjects = arrayMove(
        projects,
        oldIndex,
        newIndex,
      ) as TableTask[];

      client.cache.updateQuery(
        {
          query: GET_PROJECTS,
        },
        () => ({
          projects: newProjects,
        }),
      );

      onUpdateUser({ projectsOrder: newProjects.map(({ _id }) => _id) });
    }
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 300,
        tolerance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return (
    <Container>
      <List>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          modifiers={[
            restrictToVerticalAxis,
            restrictToFirstScrollableAncestor,
          ]}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={projectIds}
            strategy={verticalListSortingStrategy}
          >
            {projects.map(project => (
              <ProjectsListItem key={project._id} project={project} />
            ))}
          </SortableContext>
        </DndContext>
      </List>
    </Container>
  );
};

export default ProjectsList;
