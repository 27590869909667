import { RefObject, useEffect, useState } from 'react';

interface IntersectionObserverOptions {
  root?: Element | null;
  rootMargin?: string; // Add offset values (e.g., "10px 20px 30px 40px")
  threshold?: number | number[];
}

const useInViewport = (
  ref: RefObject<Element>,
  options: IntersectionObserverOptions = {},
): boolean => {
  const [isInViewport, setIsInViewport] = useState(false);

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    const observer = new IntersectionObserver(
      ([entry]) => setIsInViewport(entry.isIntersecting),
      options,
    );

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [ref, options]);

  return isInViewport;
};

export default useInViewport;
