import { ApolloCache } from '@apollo/client';

import uniqBy from 'lodash/uniqBy';

import { KanbanTasksDocument, Action, KanbanTasksQuery } from 'src/generated';

export const updateKanbanCache = (
  cache: ApolloCache<any>,
  updatedTask: any,
  previousStatusId: string | null = null,
): void => {
  cache.updateQuery(
    {
      query: KanbanTasksDocument,
      variables: { projectId: updatedTask?.projectId?._id },
    },
    (cachedTasks: KanbanTasksQuery | null) => {
      const { kanbanTasks = [] } = cachedTasks || {};
      return {
        kanbanTasks: kanbanTasks.map(column => {
          if (column._id === updatedTask.statusId) {
            const actions = uniqBy([updatedTask, ...column.actions], '_id');

            return {
              ...column,
              tasksCount: actions.length,
              actions,
            };
          }
          if (column._id === previousStatusId) {
            const actions = column.actions.filter(
              action => action._id !== updatedTask._id,
            );
            return {
              ...column,
              tasksCount: actions.length,
              actions,
            };
          }
          return column;
        }),
      };
    },
  );
};
