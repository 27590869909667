import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Flex, Avatar } from 'src/components/design-system';

import NoAssignee from '../NoAssignee/NoAssignee';

import * as Styled from './styles';

const AssigneeList = ({ members, handleUpdateAssignee }) => {
  const { t } = useTranslation();

  return (
    <Styled.Container>
      <Styled.Title>{t('task.assignee.setAssignee')}</Styled.Title>

      <Styled.List>
        <Button
          fullWidth
          htmlType="text"
          onClick={e => handleUpdateAssignee({ e, assigneeId: null })}
        >
          <Flex padding={2} alignItems="center">
            <NoAssignee />
          </Flex>
        </Button>
        {members.map(member => {
          const { _id, fullName, email } = member;

          return (
            <Button
              key={_id}
              fullWidth
              htmlType="text"
              onClick={e => handleUpdateAssignee({ e, assigneeId: _id })}
            >
              <Flex alignItems="center" padding={2}>
                <Avatar user={member} size={30} />
                <Flex direction="column" alignItems="flex-start">
                  <strong>{fullName}</strong>
                  <div>{email}</div>
                </Flex>
              </Flex>
            </Button>
          );
        })}
      </Styled.List>
    </Styled.Container>
  );
};

export default AssigneeList;
