import React, { RefObject, useMemo } from 'react';

import { Menu, Portal } from 'src/components/design-system';
import { useTheme } from 'styled-components';

import { useContextMenu } from 'src/hooks/common/useContextMenu';
import { useTask } from 'src/hooks/useTask';
import { useTimeTracking } from 'src/hooks/useTimeTracking';

import { useProject } from 'src/helpers/apollo';

import { useIsTaskCompleted } from 'src/helpers/task';
import { useUser } from 'src/store';

import { TASK_CONTEXT_MENU } from './types';

import { TimeTrackingOperation } from 'src/generated';

import * as Styled from './styles';

type Props = {
  triggerRef: RefObject<HTMLDivElement>;
  task: any;
};

const TaskContextMenu = ({ task, triggerRef }: Props) => {
  const { _id, statusId, isUrgent, isArchived } = task;
  const user = useUser();
  const { project } = useProject();
  const theme = useTheme();
  const isTaskCompleted = useIsTaskCompleted(statusId);

  const { completedStatusId, unstagedStatusId } = project;

  const { onDeleteTasks, onDuplicateTask, onUpdateTask } = useTask();

  const { isTracking, onUpdateTimeTracking } = useTimeTracking({ taskId: _id });

  const contextMenuRef = React.useRef(null);
  const { xPos, yPos, isMenuVisible, setIsMenuVisible } = useContextMenu({
    triggerRef,
    contextMenuRef,
  });

  const { timeTracking } = user;

  const menu = useMemo(() => {
    return [
      {
        id: TASK_CONTEXT_MENU.MARK_AS_URGENT,
        title: isUrgent
          ? 'project.taskContextMenu.markAsNotUrgent'
          : 'project.taskContextMenu.markAsUrgent',
        icon: 'info-triangle-filled',
        iconColor: theme.color.urgent,
      },
      {
        id: TASK_CONTEXT_MENU.TIME_TRACKING,
        title: isTracking
          ? 'project.taskContextMenu.stopTimer'
          : 'project.taskContextMenu.startTimer',
        icon: isTracking ? 'stop-circle-fill' : 'play-square-fill',
      },
      {
        id: TASK_CONTEXT_MENU.COMPLETE,
        icon: 'circle-check-filled',
        title: isTaskCompleted
          ? 'project.taskContextMenu.markIncomplete'
          : 'project.taskContextMenu.markComplete',
      },
      {
        id: TASK_CONTEXT_MENU.DUPLICATE,
        icon: 'copy',
        title: 'project.taskContextMenu.duplicate',
      },
      {
        id: TASK_CONTEXT_MENU.ARCHIVE,
        title: isArchived
          ? 'project.taskContextMenu.unArchive'
          : 'project.taskContextMenu.archive',
        icon: 'archive',
        iconColor: theme.color.grey500,
      },
      {
        id: 'divider-1',
        type: 'divider',
      },
      {
        id: TASK_CONTEXT_MENU.DELETE,
        title: 'project.taskContextMenu.delete',
        icon: 'trash',
        danger: true,
      },
    ];
  }, [isUrgent, isTracking, isTaskCompleted]);

  const handleMenuClick = id => {
    switch (id) {
      case TASK_CONTEXT_MENU.MARK_AS_URGENT:
        onUpdateTask({
          taskId: _id,
          isUrgent: !isUrgent,
        });
        break;
      case TASK_CONTEXT_MENU.TIME_TRACKING:
        onUpdateTimeTracking({
          actionId: _id,
          operation: isTracking
            ? TimeTrackingOperation.Stop
            : TimeTrackingOperation.Start,
          timeTrackingId: timeTracking?._id,
        });
        break;
      case TASK_CONTEXT_MENU.COMPLETE:
        onUpdateTask({
          taskId: _id,
          statusId: isTaskCompleted ? unstagedStatusId : completedStatusId,
        });
        break;
      case TASK_CONTEXT_MENU.DUPLICATE:
        onDuplicateTask({ taskId: _id });
        break;
      case TASK_CONTEXT_MENU.ARCHIVE:
        onUpdateTask({ taskId: _id, isArchived: !isArchived });
        break;
      case TASK_CONTEXT_MENU.DELETE:
        onDeleteTasks({ taskIds: [_id] });
        break;
      default:
        break;
    }
    setIsMenuVisible(false);
  };

  if (!isMenuVisible) return null;

  return (
    <Portal>
      <Styled.Container
        top={yPos}
        left={xPos}
        ref={contextMenuRef}
        onClick={e => e.stopPropagation()}
      >
        <Menu options={menu} handleMenuClick={handleMenuClick} />
      </Styled.Container>
    </Portal>
  );
};

export default TaskContextMenu;
