import styled from 'styled-components/macro';

export const Overlay = styled.div`
  background: ${({ theme }) => theme.background.primary};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  position: relative;
`;

export const Title = styled.h3`
  margin: 0 0 20px;
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;
