import React from 'react';

import { Modal } from 'src/components/design-system';

import TaskTemplatesList from './TaskTemplatesList';
import {
  useIsOpenTaskTemplateModal,
  useUpdateIsOpenTaskTemplateModal,
} from 'src/store';

const TaskTemplates = () => {
  const closeTaskTemplateModal = useUpdateIsOpenTaskTemplateModal();
  const isOpenTaskTemplateModal = useIsOpenTaskTemplateModal();

  if (!isOpenTaskTemplateModal) return null;

  return (
    <Modal
      width={700}
      isOpen={isOpenTaskTemplateModal}
      onRequestClose={() => closeTaskTemplateModal(false)}
      title="Task Templates"
      style={{
        overlay: {
          zIndex: 101,
        },
      }}
    >
      <TaskTemplatesList />
    </Modal>
  );
};

export default TaskTemplates;
