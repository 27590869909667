import { CommentTypeEnum } from 'src/generated';

export const stickerBucked = 'https://stikers.s3.eu-north-1.amazonaws.com/';

export const stickers = [
  // {
  //   stickersName: 'tired',
  //   count: 23,
  //   previewNumber: 0,
  //   type: CommentTypeEnum.AnimatedSticker,
  //   extension: '.tgs',
  // },
  {
    stickersName: 'animated',
    count: 650,
    previewNumber: 2,
    type: CommentTypeEnum.AnimatedSticker,
    extension: '.tgs',
  },
  {
    stickersName: 'worldArt',
    count: 33,
    previewNumber: 1,
    type: CommentTypeEnum.AnimatedSticker,
    extension: '.tgs',
  },
  // {
  //   stickersName: 'peeekaboooo',
  //   count: 52,
  //   previewNumber: 1,
  //   type: CommentTypeEnum.AnimatedSticker,
  //   extension: '.tgs',
  // },
  {
    stickersName: 'simpson',
    count: 43,
    previewNumber: 39,
    type: CommentTypeEnum.AnimatedSticker,
    extension: '.tgs',
  },
];
