import { create } from 'zustand';

interface AppStore {
  isOpenTaskTemplateModal: boolean;
  updateIsOpenTaskTemplateModal: (isOpen: boolean) => void;
}

export const useAppStore = create<AppStore>(set => ({
  isOpenTaskTemplateModal: false,
  updateIsOpenTaskTemplateModal: (isOpen: boolean) =>
    set({ isOpenTaskTemplateModal: isOpen }),
}));

export const useIsOpenTaskTemplateModal = () =>
  useAppStore(state => state.isOpenTaskTemplateModal);

export const useUpdateIsOpenTaskTemplateModal = () =>
  useAppStore(state => state.updateIsOpenTaskTemplateModal);
